import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Spinner,
  Table,
  Tooltip,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
// import Pagination from "../Common/Pagination";
import { Modal } from "react-responsive-modal";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import * as XLSX from "xlsx";
import excelFormat2 from "../../Components/excel/regional _supervisor.xlsx";
import excelFormat from "../../Components/excel/so_officer.xlsx";
import officer1 from "../../Components/ProfilePage/img/officer1.png";
import { toaster } from "../../Custom Helper/Custom/Excel toaster";
import { get, post } from "../../helpers/api_helper";
import useExcelReader from "../../Hooks/useExcelReader";
import {
  getDistrictByDivision,
  getDistrictValue,
  getDivisionValue,
  getUpazilaValue,
} from "../../store/GeoInformation/action.js";
import {
  addUser,
  getSearchInstitute,
  getSuperVisor,
  getUserDetails,
  getUsers,
  storeUserData,
  updateUser,
} from "../../store/User/actions";
import AddFileUpload from "../Common/AddFileUpload";
import Pagination from "../Common/Pagination";
import profileImage from "./profile.png";

const UserListView = () => {
  const dispatch = useDispatch();

  const history = useHistory();
  const [selectedLineManager, setSelectedLineManager] = useState(null);
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const [openSecond, setOpenSecond] = useState(false);
  const [uodateUser, setuodateUser] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [district, setDistrict] = useState("");
  const [division, setDivision] = useState("");
  const [upazila, setUpazila] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhoneNumber] = useState("");
  const [role, setRole] = useState("");
  const [userRole, setUserRole] = useState("All");
  const [email, setEmail] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [password, setPassword] = useState("");
  const [confrimPassword, setConfirmPassWord] = useState("");
  const [upazilaByDist, setUpazilaByDist] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [handleList, setHandleList] = useState(10);
  const { items, readExcel } = useExcelReader("");
  const [updateObj, setUpdateObj] = useState({});

  const [results, setResults] = useState([]);
  const [isDone, setIsDone] = useState(false);
  const [openExcel, setOpenExcel] = useState(false);
  const [adding, setAdding] = useState(false);
  const toggle = () => setOpenExcel(!openExcel);

  const [selectedFiles, setSelectedFiles] = useState({});
  const [loading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState("");
  const [key, setKey] = useState("");
  const [objData, setObjData] = useState({});
  const {
    authtoken,
    upazilaData,
    users,
    getUserLoading,
    superVisor,
    superVisorLoading,
    divisionData,
    districtData,
    districtByDivisionData,
    userDetails,
    editUserInfo,
    loginInfo,
    searchInstituteData,
  } = useSelector((state) => ({
    authtoken: state.Login.token,
    loginInfo: state.Login,
    users: state?.UserReducer?.users,
    divisionData: state.GeoInformationReducer.divisionData,
    districtData: state.GeoInformationReducer.districtData,
    getUserLoading: state?.UserReducer?.getUserLoading,
    superVisor: state?.UserReducer?.superVisor,
    superVisorLoading: state?.UserReducer?.superVisorLoading,
    districtByDivisionData:
      state?.GeoInformationReducer?.districtByDivisionData,
    userDetails: state?.UserReducer?.userDetails,
    upazilaData: state.GeoInformationReducer.upazilaData,
    editUserInfo: state?.UserReducer?.editUserInfo,
    searchInstituteData: state?.UserReducer?.searchInstituteData,
  }));
  let totalPageNumber = Math.ceil(users?.data?.data?.totalLength / handleList);

  const handleSearch = (e) => {
    if (e.key === "Enter") {
      setSearchData(e.target.value);
      if (e.target.value) {
        dispatch(getSearchInstitute(authtoken, e.target.value));
      } else {
        setSearchData("");
        dispatch(getSuperVisor(authtoken, "All", handleList, currentPage));
      }
    }
  };

  useEffect(() => {
    dispatch(getUsers(authtoken, userRole, handleList, currentPage));
  }, [userRole, handleList, currentPage]);

  useEffect(() => {
    dispatch(getSuperVisor(authtoken, "RS", 0, 0));
  }, []);
  useEffect(() => {
    dispatch(getDivisionValue(authtoken, 0, 0));
  }, []);
  const handleDivision = (data) => {
    setDivision(data._id);
    setObjData({
      ...objData,
      [key]: {
        ...objData[key],
        division: data._id,
      },
    });
    if (!!data) {
      dispatch(getDistrictByDivision(authtoken, data._id, 0, 0));
    } else {
      dispatch(getDistrictByDivision({}));
    }
  };
  //upazila list
  useEffect(() => {
    if (upazilaByDist) {
      dispatch(getUpazilaValue(authtoken, 0, 0, upazilaByDist));
    } else {
      dispatch(getUpazilaValue({}));
    }
  }, [upazilaByDist]);
  const handleDistrict = (value) => {
    setDistrict(value._id);
    setObjData({
      ...objData,
      [key]: {
        ...objData[key],
        district: value._id,
      },
    });
    if (!!value) {
      setUpazilaByDist(value);
    } else {
      setUpazilaByDist();
    }
  };
  const handleUpazila = (value) => {
    setUpazila(value._id);
    setObjData({
      ...objData,
      [key]: {
        ...objData[key],
        upazila: value._id,
      },
    });
  };
  useEffect(() => {
    dispatch(getDistrictValue(authtoken, 0, 0, 0));
  }, []);
  useEffect(() => {
    dispatch(getUpazilaValue(authtoken, 0, 0, 0));
  }, []);

  const handleListData = (e) => {
    setHandleList(e.target.value);
  };

  async function handleSubmit(event, errors, values) {
    console.log("hghj", "hjgjhg");
    event.preventDefault();

    let obj = {};
    obj.name = name;
    obj.phoneNumber = phone;
    obj.email = email;
    obj.role = role;
    obj.employeeId = employeeId;
    obj.linemanager = selectedLineManager;
    if (division) {
      obj.division = division;
    }
    if (district) {
      obj.district = district;
    }
    if (upazila) {
      obj.upazila = upazila;
    }
    obj.password = password;
    obj.passwordConfirm = confrimPassword;
    console.log("obj", obj);

    dispatch(addUser(obj, history, authtoken));
    setOpen(false);
  }

  const handleChangeRole = (e) => {
    setRole(e.target.value);
    setObjData({
      ...objData,
      [key]: {
        ...objData[key],
        role: e.target.value,
      },
    });

    if (e.target.value == "ADMIN") {
      setIsDisabled(true);
    }
    if (e.target.value == "NCO") {
      setIsDisabled(true);
    }
    if (e.target.value == "RS") {
      setIsDisabled(true);
    }
    if (e.target.value == "SO") {
      setIsDisabled(false);
    }
    if (e.target.value == "DISTRIBUTOR") {
      setIsDisabled(true);
    }
  };
  const handleChangeUserRole = (e) => {
    setUserRole(e.target.value);
  };
  const handleLineManager = (value) => {
    setSelectedLineManager(value._id);
    setObjData({
      ...objData,
      [key]: {
        ...objData[key],
        linemanager: value._id,
      },
    });
  };
  const handleUserDetails = (data) => {
    setOpenSecond(true);
    dispatch(getUserDetails(authtoken, data._id));
  };
  console.log("users", users);

  //edit division
  const handleEditSubmit = (e, data) => {
    e.preventDefault();
    let obj = {};
    obj.name = objData?.[key]?.name ? objData?.[key]?.name : data?.name;
    obj.phoneNumber = objData?.[key]?.phoneNumber
      ? objData?.[key]?.phoneNumber
      : data?.phoneNumber;
    obj.email = objData?.[key]?.email ? objData?.[key]?.email : data?.email;
    obj.role = objData?.[key]?.role ? objData?.[key]?.role : data?.role;
    obj.employeeId = objData?.[key]?.employeeId
      ? objData?.[key]?.employeeId
      : data?.employeeId;
    obj.linemanager = objData?.[key]?.linemanager
      ? objData?.[key]?.linemanager
      : data?.linemanager;
    obj.division = objData?.[key]?.division
      ? objData?.[key]?.division
      : data?.division?._id;
    obj.district = objData?.[key]?.district
      ? objData?.[key]?.district
      : data?.district?._id;
    obj.upazila = objData?.[key]?.upazila
      ? objData?.[key]?.upazila
      : data?.upazila?._id;
    obj.password = password;
    obj.passwordConfirm = confrimPassword;

    dispatch(
      updateUser(data._id, obj, authtoken, history, handleList, currentPage)
    );
    setuodateUser(false);
  };

  const handleEditUser = (data, key) => {
    setKey(key);
    dispatch(storeUserData("singleUser", data, key));
  };

  <select
    className="form-select m-auto"
    value={role}
    onChange={handleChangeRole}
  >
    <option value="All">All</option>
    <option value="ADMIN">Admin</option>
    <option value="NCO">National Coordinator</option>
    <option value="RS">Regional Supervisor</option>
    <option value="SO">Support Officer</option>
  </select>;
  const [showPass, setshowPass] = React.useState(false);
  const [showPass2, setshowPass2] = React.useState(false);

  const excelSubmit = async () => {
    setAdding(true);
    for (let i = 0; i < items.length; i++) {
      const element = items[i];

      const data = {
        SO_Name: element?.Name,
        email: element?.Email,
        ID: element?.ID,
        Contact: element?.Contact,
        RS_ID: element?.RS_ID,
        role: element?.Role,
        // division: element.DIVISION,
        // district: element.DISTRICT,
        // upazila: element.UPAZILA,
      };

      await post("/users/user-excel", data, {
        headers: { Authorization: `Bearer ${authtoken}` },
      })
        .then((response) => {
          setResults((prev) => [...prev, response]);
        })
        .catch((error) => {
          setResults((prev) => [...prev, { status: "failed" }]);
        });

      if (i === items.length - 1) {
        setIsDone(true);
        setAdding(false);
        toaster("success", "All data upload successful");
      }
    }
  };

  const tableHead = items?.[0] || {};
  const handleExcelDownload = async () => {
    setLoading(true);
    let userData = [];

    await get(`/users/user-list/${userRole}/0/0`, {
      headers: { Authorization: `Bearer ${authtoken}` },
    })
      .then((response) => {
        response?.users?.forEach((user, index) => {
          let data = {};
          data.Name = user?.name;
          data.UserId = user?.employeeId;
          data.Email = user?.email;
          data.PhoneNumber = user?.phoneNumber;
          data.Role = user?.role;
          data.Upazila = user?.upazila?.name;
          data.District = user?.district?.name;
          data.Division = user?.division?.name;
          userData.push(data);
        });
      })
      .catch((error) => {
        console.log("kkkk");
      });
    downloadxls(userData);
  };
  const downloadxls = (data) => {
    console.log(XLSX.version);
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    XLSX.writeFile(wb, "User.xlsx");
    setLoading(false);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={onCloseModal}
        center
        classNames={{
          overlay: "add-divisionOverlay",
          modal: "add-division-Modal",
        }}
      >
        <div className="add-division-popup-form">
          <div className="mb-4 text-center">
            <h5 className="">Create User</h5>
          </div>
          <Form onSubmit={handleSubmit} className="mb-5">
            <Row>
              <Col lg={6}>
                {" "}
                <Form.Group className="mb-3">
                  <Form.Label>Full name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Type Full Name"
                    name="name"
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Group>{" "}
              </Col>{" "}
              <Col lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Type Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Type  Email"
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>{" "}
              <Col lg={6}>
                {" "}
                <Form.Group className="mb-3">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Type Phone Number"
                    name="phoneNumber"
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    required
                  />
                </Form.Group>{" "}
              </Col>
              <Col lg={6}>
                {" "}
                <Form.Group className="mb-3">
                  <Form.Label>Select Role</Form.Label>
                  <select
                    className="form-select m-auto"
                    value={role}
                    onChange={handleChangeRole}
                    required
                  >
                    <option value="">Select Role</option>
                    <option value="ADMIN">Admin</option>
                    <option value="NCO">National Coordinator</option>
                    <option value="RS">Regional Supervisor</option>
                    <option value="SO">Support Officer</option>
                    <option value="Demo RS">Demo RS</option>
                    <option value="Demo SO">Demo SO</option>
                    <option value="Demo Admin">Demo Admin</option>
                  </select>
                </Form.Group>{" "}
              </Col>{" "}
              <Col lg={6}>
                {" "}
                <Form.Group className="mb-3">
                  <Form.Label>Select Regional Supervisor</Form.Label>
                  <Select
                    name="linemanager"
                    isDisabled={isDisabled}
                    getOptionLabel={(e) => e.name}
                    getOptionValue={(e) => e._id}
                    options={superVisor?.data?.data?.users}
                    onChange={handleLineManager}
                  />
                </Form.Group>{" "}
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>User ID</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Type  user id"
                    name="employeeId"
                    onChange={(e) => setEmployeeId(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>{" "}
              <Col lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Select Division</Form.Label>
                  <Select
                    name="division"
                    options={divisionData?.divisions}
                    getOptionLabel={(e) => e.name}
                    getOptionValue={(e) => e.value}
                    placeholder="Select Division"
                    isClearable
                    onChange={handleDivision}
                  />
                </Form.Group>
              </Col>{" "}
              <Col lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Upload user image</Form.Label>
                  <Form.Control type="file" />
                </Form.Group>
              </Col>{" "}
              <Col lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Select District</Form.Label>
                  <Select
                    name="district"
                    options={districtByDivisionData?.districts}
                    getOptionLabel={(e) => e.name}
                    getOptionValue={(e) => e.value}
                    placeholder="Select District"
                    onChange={handleDistrict}
                    isClearable
                  />
                </Form.Group>
              </Col>{" "}
              <Col lg={6}>
                <Form.Group className="mb-3 form-password-group-show-hide">
                  <Form.Label>Type Password</Form.Label>
                  <Form.Control
                    type={showPass ? "text" : "password"}
                    placeholder="Type Password"
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <span onClick={() => setshowPass(!showPass)}>
                    {showPass ? (
                      <span>
                        <i class="fa-solid fa-eye"></i>
                      </span>
                    ) : (
                      <span>
                        <i class="fa-solid fa-eye-slash"></i>
                      </span>
                    )}
                  </span>
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Select Upazila</Form.Label>
                  <Select
                    name="upazila"
                    placeholder="Select Upazila"
                    getOptionLabel={(e) => e.name}
                    getOptionValue={(e) => e.value}
                    options={upazilaData?.upazilas}
                    onChange={handleUpazila}
                    isClearable
                  />
                </Form.Group>
              </Col>{" "}
              <Col lg={6}>
                <Form.Group className="mb-3 form-password-group-show-hide">
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type={showPass2 ? "text" : "password"}
                    placeholder="Type Confirm Password"
                    name="passwordConfirm"
                    onChange={(e) => setConfirmPassWord(e.target.value)}
                    required
                  />
                  <span onClick={() => setshowPass2(!showPass2)}>
                    {showPass2 ? (
                      <span>
                        <i class="fa-solid fa-eye"></i>
                      </span>
                    ) : (
                      <span>
                        <i class="fa-solid fa-eye-slash"></i>
                      </span>
                    )}
                  </span>
                </Form.Group>
              </Col>
              <Col lg={6}>
                {" "}
                <Button variant="success" type="submit">
                  Create User
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
        <div className="d-flex align-items-center justify-content-between mt-5 mb-4">
          <div className="mt-3">
            <h6>Upload Excel</h6>
          </div>
          <div className="ml-auto">
            <a href={excelFormat2}>
              <button className="btn btn-secondary me-3 mt-2">
                <i class="fa fa-download me-1" aria-hidden="true"></i>Download
                Format (RS)
              </button>
            </a>
            <a href={excelFormat}>
              <button className="btn btn-secondary mt-2">
                <i class="fa fa-download me-1" aria-hidden="true"></i>Download
                Format (SO)
              </button>
            </a>
          </div>
        </div>
        <AddFileUpload
          setSelectedFiles={setSelectedFiles}
          acceptFile={
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          }
          handleFile={(file) => {
            readExcel(file);
          }}
        />
        <Modal
          open={openExcel}
          toggle={toggle}
          size="lg"
          onClose={() => setOpenExcel(!openExcel)}
        >
          {/* <ModalBody> */}
          <Table className="table table-responsive">
            <thead>
              <tr>
                <th>No.</th>
                {Object.keys(tableHead)?.map((item, idx) => (
                  <th key={idx}>{item}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {items?.map((item, idx) => (
                <tr key={idx}>
                  <td>{idx + 1}</td>
                  <td>{item?.Name}</td>
                  <td>{item?.Contact}</td>
                  <td>{item?.ID}</td>
                  <td>{item?.Email}</td>
                  <td>{item?.RS_Name}</td>
                  <td>{item?.RS_ID}</td>
                  <td>{item?.Role}</td>
                  <td>{item?.DIVISION}</td>
                  <td>{item?.DISTRICT}</td>
                  <td>{item?.UPAZILA}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          {/* </ModalBody> */}
        </Modal>
        {items?.length > 0 ? (
          <div className="mt-4 text-center">
            <button className="btn btn-primary me-2" onClick={toggle}>
              Preview
            </button>
            <button
              disabled={adding}
              className="btn btn-primary "
              onClick={excelSubmit}
            >
              {adding ? "Submitting..." : "Submit"}
            </button>
          </div>
        ) : null}
      </Modal>
      <Modal
        open={openSecond}
        onClose={() => setOpenSecond(false)}
        center
        classNames={{
          overlay: "add-divisionOverlay",
          modal: "add-division-Modal profile-view-bndfv issue_modal",
        }}
      >
        <div className="add-division-popup-form">
          <div className="add-division-title-top">
            <div className="text-center mb-4 mt-5">
              <h5>User Details</h5>
              <img src={profileImage} className="my-3" alt="" />
            </div>
            <Row>
              <Col lg={6}>
                {" "}
                <Form.Group className="mb-3">
                  <Form.Label>Full name</Form.Label> <br />
                  <Form.Text className="text-muted">
                    {userDetails?.data?.data?.users?.name}
                  </Form.Text>
                </Form.Group>{" "}
              </Col>{" "}
              <Col lg={6}>
                {" "}
                <Form.Group className="mb-3">
                  <Form.Label>Phone Number</Form.Label> <br />
                  <Form.Text className="text-muted">
                    {userDetails?.data?.data?.users?.phoneNumber}
                  </Form.Text>
                </Form.Group>{" "}
              </Col>
              <Col lg={6}>
                {" "}
                <Form.Group className="mb-3">
                  <Form.Label> Role</Form.Label>
                  <br />
                  <Form.Text className="text-muted">
                    {userDetails?.data?.data?.users?.role}
                  </Form.Text>
                </Form.Group>{" "}
              </Col>{" "}
              {userDetails?.data?.data?.users?.role !== "RS" ||
              userDetails?.data?.data?.users?.role !== "Demo RS" ? (
                <Col lg={6}>
                  {" "}
                  <Form.Group className="mb-3">
                    <Form.Label> Regional Supervisor</Form.Label>
                    <br />
                    <Form.Text className="text-muted">
                      {userDetails?.data?.data?.users?.linemanager?.name}
                    </Form.Text>
                  </Form.Group>{" "}
                </Col>
              ) : (
                <></>
              )}
              <Col lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label> Email</Form.Label>

                  <br />
                  <Form.Text className="text-muted">
                    {" "}
                    {userDetails?.data?.data?.users?.email}
                  </Form.Text>
                </Form.Group>
              </Col>{" "}
              <Col lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>User ID</Form.Label> <br />
                  <Form.Text>
                    {" "}
                    {userDetails?.data?.data?.users?.employeeId}
                  </Form.Text>
                </Form.Group>
              </Col>{" "}
            </Row>
            <hr />
            {userDetails?.data?.data?.users?.role === "SO" ? (
              <Table borderless striped>
                <thead>
                  <tr>
                    <th>SL.</th>
                    <th>Institute Name</th>
                    <th>Head Name</th>
                    <th>Phone Number </th>
                    <th>Address</th>
                  </tr>
                </thead>
                {(userDetails?.data?.data?.instituteList || []).map(
                  (data, key) => {
                    return (
                      <>
                        <tbody>
                          <tr>
                            <td>{key + 1}</td>
                            <td>{data?.nameEnglish}</td>
                            <td>{data?.headOfInstitute}</td>
                            <td>{data?.contactNumber}</td>
                            <td>{data?.address}</td>
                          </tr>{" "}
                        </tbody>
                      </>
                    );
                  }
                )}
              </Table>
            ) : userDetails?.data?.data?.users?.role === "RS" ||
              userDetails?.data?.data?.users?.role === "Demo RS" ? (
              <div className="sopport-officer-wrap">
                <div className="support-officer-item-wrap">
                  <div className="mb-4">
                    <h5>Support Officer</h5>
                  </div>
                  <Row>
                    {(userDetails?.data?.data?.soUserList || []).map(
                      (data, key) => {
                        return (
                          <>
                            <Col lg={3}>
                              <div className="support-officer-single-item mb-5 ">
                                <div className="text-center">
                                  <img src={officer1} alt="Profile" />
                                  <h6 style={{ fontSize: "13px" }}>
                                    {data?.name}
                                  </h6>
                                  <Form.Text className="text-muted">
                                    {data?.email}
                                  </Form.Text>
                                </div>
                              </div>
                            </Col>
                          </>
                        );
                      }
                    )}
                  </Row>
                </div>
              </div>
            ) : (
              <span></span>
            )}
          </div>
        </div>
      </Modal>
      <Modal
        open={uodateUser}
        onClose={() => setuodateUser(false)}
        center
        classNames={{
          overlay: "add-divisionOverlay",
          modal: "add-division-Modal issue_modal",
        }}
      >
        <div className="add-division-popup-form">
          <div className="mb-4 mt-4 text-center">
            <h5>Update User</h5>
          </div>
          <Form>
            <Row>
              <Col lg={6}>
                {" "}
                <Form.Group className="mb-3">
                  <Form.Label>Full name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Type Full Name"
                    name="name"
                    onChange={(e) => {
                      setObjData({
                        ...objData,
                        [key]: {
                          ...objData[key],
                          name: e.target.value,
                        },
                      });
                    }}
                    defaultValue={editUserInfo?.[key]?.data?.name}
                  />
                </Form.Group>{" "}
              </Col>{" "}
              <Col lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Type Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Type  Email"
                    name="email"
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setObjData({
                        ...objData,
                        [key]: {
                          ...objData[key],
                          email: e.target.value,
                        },
                      });
                    }}
                    defaultValue={editUserInfo?.[key]?.data?.email}
                  />
                </Form.Group>
              </Col>{" "}
              <Col lg={6}>
                {" "}
                <Form.Group className="mb-3">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Type Phone Number"
                    name="phoneNumber"
                    onChange={(e) => {
                      setPhoneNumber(e.target.value);
                      setObjData({
                        ...objData,
                        [key]: {
                          ...objData[key],
                          phoneNumber: e.target.value,
                        },
                      });
                    }}
                    defaultValue={editUserInfo?.[key]?.data?.phoneNumber}
                  />
                </Form.Group>{" "}
              </Col>
              <Col lg={6}>
                {" "}
                <Form.Group className="mb-3">
                  <Form.Label>Select Role</Form.Label>
                  <select
                    className="form-select m-auto"
                    // value={role}
                    onChange={handleChangeRole}
                  >
                    <option value="">Select role</option>
                    <option
                      value="ADMIN"
                      selected={
                        editUserInfo?.[key]?.data?.role === "ADMIN"
                          ? "true"
                          : ""
                      }
                    >
                      Admin
                    </option>
                    <option
                      value="NCO"
                      selected={
                        editUserInfo?.[key]?.data?.role === "NCO" ? "true" : ""
                      }
                    >
                      National Coordinator
                    </option>
                    <option
                      value="RS"
                      selected={
                        editUserInfo?.[key]?.data?.role === "RS" ? "true" : ""
                      }
                    >
                      Regional Supervisor
                    </option>
                    <option
                      value="SO"
                      selected={
                        editUserInfo?.[key]?.data?.role === "SO" ? "true" : ""
                      }
                    >
                      Support Officer
                    </option>
                    <option
                      value="Demo RS"
                      selected={
                        editUserInfo?.[key]?.data?.role === "Demo RS"
                          ? "true"
                          : ""
                      }
                    >
                      Demo RS
                    </option>
                    <option
                      value="Demo SO"
                      selected={
                        editUserInfo?.[key]?.data?.role === "Demo SO"
                          ? "true"
                          : ""
                      }
                    >
                      Demo SO
                    </option>
                    <option
                      value="Demo Admin"
                      selected={
                        editUserInfo?.[key]?.data?.role === "Demo Admin"
                          ? "true"
                          : ""
                      }
                    >
                      Demo Admin
                    </option>
                  </select>
                </Form.Group>{" "}
              </Col>{" "}
              <Col lg={6}>
                {" "}
                <Form.Group className="mb-3">
                  <Form.Label>Select Regional Supervisor</Form.Label>
                  <Select
                    name="linemanager"
                    cacheOptions
                    isDisabled={isDisabled}
                    getOptionLabel={(e) => e.name}
                    getOptionValue={(e) => e._id}
                    options={superVisor?.data?.data?.users}
                    defaultOptions
                    onChange={handleLineManager}
                    defaultValue={superVisor?.data?.data?.users?.filter(
                      (dta) =>
                        dta.value === editUserInfo?.[key]?.data?.linemanager
                    )}
                  />
                </Form.Group>{" "}
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>User ID</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Type  user id"
                    name="employeeId"
                    onChange={(e) => {
                      setEmployeeId(e.target.value);
                      setObjData({
                        ...objData,
                        [key]: {
                          ...objData[key],
                          employeeId: e.target.value,
                        },
                      });
                    }}
                    defaultValue={editUserInfo?.[key]?.data?.employeeId}
                  />
                </Form.Group>
              </Col>{" "}
              <Col lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Select Division</Form.Label>
                  <Select
                    name="division"
                    options={divisionData?.divisions}
                    getOptionLabel={(e) => e.name}
                    getOptionValue={(e) => e.value}
                    placeholder="Select Division"
                    isClearable
                    onChange={handleDivision}
                    defaultValue={divisionData?.divisions?.filter(
                      (dta) =>
                        dta._id === editUserInfo?.[key]?.data?.division?._id
                    )}
                  />
                </Form.Group>
              </Col>{" "}
              <Col lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Upload user image</Form.Label>
                  <Form.Control type="file" />
                </Form.Group>
              </Col>{" "}
              <Col lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Select District</Form.Label>
                  <Select
                    name="district"
                    options={districtByDivisionData?.districts}
                    getOptionLabel={(e) => e.name}
                    getOptionValue={(e) => e.value}
                    placeholder="Select District"
                    onChange={handleDistrict}
                    isClearable
                    defaultValue={districtData?.districts?.filter(
                      (dta) =>
                        dta._id === editUserInfo?.[key]?.data?.district?._id
                    )}
                  />
                </Form.Group>
              </Col>{" "}
              {/* <Col lg={6}>
                <Form.Group className="mb-3 form-password-group-show-hide">
                  <Form.Label>Type Password</Form.Label>
                  <Form.Control
                    type={showPass ? "text" : "password"}
                    placeholder="Type Password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <span onClick={() => setshowPass(!showPass)}>
                    {showPass ? (
                      <span>
                        <i class="fa-solid fa-eye"></i>
                      </span>
                    ) : (
                      <span>
                        <i class="fa-solid fa-eye-slash"></i>
                      </span>
                    )}
                  </span>
                </Form.Group>
              </Col> */}
              <Col lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Select Upazila</Form.Label>
                  <Select
                    name="upazila"
                    placeholder="Select Upazila"
                    getOptionLabel={(e) => e.name}
                    getOptionValue={(e) => e.value}
                    options={upazilaData?.upazilas}
                    onChange={handleUpazila}
                    defaultValue={upazilaData?.upazilas?.filter(
                      (dta) =>
                        dta._id === editUserInfo?.[key]?.data?.upazila?._id
                    )}
                    isClearable
                  />
                </Form.Group>
              </Col>{" "}
              {/* <Col lg={6}>
                <Form.Group className="mb-3 form-password-group-show-hide">
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type={showPass2 ? "text" : "password"}
                    placeholder="Type Confirm Password"
                    name="passwordConfirm"
                    onChange={(e) => setConfirmPassWord(e.target.value)}
                  />
                  <span onClick={() => setshowPass2(!showPass2)}>
                    {showPass2 ? (
                      <span>
                        <i class="fa-solid fa-eye"></i>
                      </span>
                    ) : (
                      <span>
                        <i class="fa-solid fa-eye-slash"></i>
                      </span>
                    )}
                  </span>
                </Form.Group>
              </Col> */}
              <Col lg={12} className="text-end mt-4">
                {" "}
                <Button
                  variant="success"
                  type="submit"
                  onClick={(e) => handleEditSubmit(e, editUserInfo?.[key].data)}
                >
                  Update User
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
      <div className="user-header-top-main-area-wrap- mb-4">
        <Row>
          <Col className="mb-2">
            <div className="user-header-top-single-item">
              <div className="content">
                <p className="mb-2">Admin </p>
                <h6>{users?.data?.data?.totalUserByRole?.admin}</h6>
              </div>
              <div className="icon"></div>
            </div>
          </Col>{" "}
          <Col className="mb-2">
            <div className="user-header-top-single-item">
              <div className="content">
                <p className="mb-2">National Coordinator</p>
                <h6>{users?.data?.data?.totalUserByRole?.nco}</h6>
              </div>
              <div className="icon"></div>
            </div>
          </Col>{" "}
          <Col className="mb-2">
            <div className="user-header-top-single-item">
              <div className="content">
                <p className="mb-2">Regional Supervisor</p>
                <h6>{users?.data?.data?.totalUserByRole?.rs}</h6>
              </div>
              <div className="icon"></div>
            </div>
          </Col>{" "}
          <Col className="mb-2">
            <div className="user-header-top-single-item">
              <div className="content">
                <p className="mb-2">Support Officer</p>
                <h6>{users?.data?.data?.totalUserByRole?.so}</h6>
              </div>
              <div className="icon"></div>
            </div>
          </Col>{" "}
        </Row>
      </div>
      <div className="all-user-main-wra-area">
        <div className="all-user-filter-search-area mb-4 jhgdbjksldjfhvbnmcknjfb mb-5">
          <h4>User List</h4>
          <div className="all-user-inner-wrap">
            <div className="search-box">
              <div className="header-topbar-right-content-wrap-left-area">
                <div className="search-box-header-top-wrap">
                  <i className="fas fa-search"></i>
                  <input
                    type="text"
                    name=""
                    id=""
                    placeholder="Search..."
                    onKeyDown={(e) => handleSearch(e)}
                  />
                </div>
              </div>
            </div>
            <div className="filter-box add-division-btn">
              {loginInfo.userrole == "Demo Admin" ? (
                <button className="btn btn-secondary" disabled>
                  Download Excel
                </button>
              ) : (
                <button
                  className="btn btn-success"
                  onClick={handleExcelDownload}
                  disabled={loading}
                >
                  {loading ? "Downloading..." : "Download Excel"}
                </button>
              )}
            </div>
            <div className="filter-box">
              <select
                className="form-select m-auto"
                value={userRole}
                onChange={handleChangeUserRole}
              >
                <option value="All">All</option>
                <option value="ADMIN">Admin</option>
                <option value="NCO">National Coordinator</option>
                <option value="RS">Regional Supervisor</option>
                <option value="SO">Support Officer</option>
                <option value="Demo RS">Demo RS</option>
                <option value="Demo SO">Demo SO</option>
                <option value="Demo Admin">Demo Admin</option>
              </select>
            </div>{" "}
            <div className="filter-box">
              <select className="form-select m-auto" onChange={handleListData}>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>{" "}
            <div className="filter-box add-division-btn">
              {loginInfo.userrole == "Demo Admin" ? (
                <button className="btn btn-secondary" disabled>
                  Create User
                </button>
              ) : (
                <button className="btn btn-success" onClick={onOpenModal}>
                  Create User
                </button>
              )}
            </div>
          </div>
        </div>

        {getUserLoading ? (
          <div className="text-center mt-5 pt-5">
            <div className="text-center pt-5">
              <Spinner animation="border" variant="warning" />
            </div>
          </div>
        ) : (
          <div className="user-data-main-area-wrap">
            <Table borderless>
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Full Name </th>
                  <th>User ID</th>
                  <th>Email</th>
                  <th>Phone Number </th>
                  <th>Role</th>
                  <th>Upazila</th>
                  <th>District</th>
                  <th>Division</th>
                  <th>Action</th>
                </tr>
              </thead>
              {(searchData
                ? searchInstituteData?.data?.data?.users || []
                : users?.data?.data?.users || []
              ).map((data, key) => {
                return (
                  <>
                    <tbody>
                      <tr>
                        <td>
                          {key +
                            (currentPage == 1
                              ? 0
                              : currentPage * handleList - handleList) +
                            1}
                        </td>
                        <td>{data?.name}</td>
                        <td>{data?.employeeId}</td>
                        <td>{data?.email}</td>
                        <td>{data?.phoneNumber}</td>
                        <td>{data?.role}</td>
                        <td>{data?.upazila?.name}</td>
                        <td>{data?.district?.name}</td>
                        <td>{data?.division?.name}</td>

                        <td>
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-disabled">View</Tooltip>
                            }
                          >
                            <span className="d-inline-block">
                              <Button
                                className="btn btn-info me-2 btn-sm text-white"
                                onClick={() => handleUserDetails(data)}
                              >
                                <i class="fa-regular fa-eye"></i>
                              </Button>
                            </span>
                          </OverlayTrigger>
                          {loginInfo.userrole == "Demo Admin" ? (
                            <span className="d-inline-block">
                              <Button
                                variant="secondary"
                                className="btn btn-sm text-white"
                                disabled
                              >
                                <i class="fa-regular fa-pen-to-square"></i>
                              </Button>
                            </span>
                          ) : (
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="tooltip-disabled">Edit</Tooltip>
                              }
                            >
                              <span className="d-inline-block">
                                <Button
                                  variant="success"
                                  className="btn btn-sm text-white"
                                  onClick={() => {
                                    setuodateUser(true);
                                    handleEditUser(data, key);
                                  }}
                                >
                                  <i class="fa-regular fa-pen-to-square"></i>
                                </Button>
                              </span>
                            </OverlayTrigger>
                          )}
                        </td>
                      </tr>{" "}
                    </tbody>
                  </>
                );
              })}
            </Table>
            <Pagination
              parentClass={`paginationContainer text-right py-3`}
              reInitialize="reInitialize"
              page={totalPageNumber}
              setCurrentPage={setCurrentPage}
            />

            {/* <div className="empty-user-area">
            <p>
              Currently you have no user.{" "}
              <Link to="/create-user">create User</Link>
            </p>
          </div> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default UserListView;
