import { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-responsive-modal";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import * as XLSX from "xlsx";
import { IMG_API, post } from "../../helpers/api_helper";
import {
  getDistrictByDivision,
  getDivisionValue,
  getParliamentaryValue,
  getUpazilaValue,
} from "../../store/GeoInformation/action.js";
import { getInstitution, getSearchPjp } from "../../store/Instituition/actions";
import {
  getSingleInstitution,
  storeInstituteData,
} from "../../store/Instituition/actions.js";
import Pagination from "../Common/Pagination";

const InstituteFiltering = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [openFirst, setOpenFirst] = useState(false);
  const [pageRange, setPageRange] = useState(20);
  const [district, setDistrict] = useState("");
  const [division, setDivision] = useState("");
  const [upazila, setUpazila] = useState("");
  const [upazilaByDist, setUpazilaByDist] = useState("");
  // const [parlimentaryByDist,setParliamentaryByDist] = useState('')
  const [parliamentaryByDist, setParliamentaryByDist] = useState("");
  const [electoral, setElectoral] = useState("");
  const [management, setManagement] = useState("");
  const [instituitionList, setInstituitionList] = useState();
  const [level, setLabel] = useState("");
  const [student, setStudent] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState("");
  const managementType = [
    {
      value: "GOVERNMENT",
      name: "GOVERNMENT",
    },
    {
      value: "NON-GOVERNMENT",
      name: "NON-GOVERNMENT",
    },
  ];

  const institutionList = [
    {
      value: "School",
      name: "School",
    },
    {
      value: "College",
      name: "College",
    },
    {
      value: "School & College",
      name: "School & College",
    },
  ];
  const studentType = [
    {
      value: "BOYS",
      name: "BOYS",
    },
    {
      value: "GIRLS",
      name: "GIRLS",
    },
    {
      value: "CO-EDUCATION JOINT",
      name: "CO-EDUCATION JOINT",
    },
  ];
  const labelList = [
    {
      value: "Secondary",
      name: "Secondary",
    },
  ];

  const {
    authtoken,
    upazilaData,
    users,
    getUserLoading,
    superVisor,
    superVisorLoading,
    divisionData,
    districtData,
    districtByDivisionData,
    parliamentaryData,
    instituition,
    instituitionLoading,
    singleInstituteData,
    singleInstituteLoading,
    searchPjpData,
    loginInfo,
  } = useSelector((state) => ({
    authtoken: state.Login.token,
    users: state?.UserReducer?.users,
    divisionData: state.GeoInformationReducer.divisionData,
    districtData: state.GeoInformationReducer.districtData,
    parliamentaryData: state.GeoInformationReducer.parliamentaryData,
    getUserLoading: state?.UserReducer?.getUserLoading,
    superVisor: state?.UserReducer?.superVisor,
    superVisorLoading: state?.UserReducer?.superVisorLoading,
    districtByDivisionData:
      state?.GeoInformationReducer?.districtByDivisionData,
    upazilaData: state.GeoInformationReducer.upazilaData,
    instituition: state.InstituteReducer.instituition,
    searchPjpData: state.InstituteReducer.pjpData,
    instituitionLoading: state.InstituteReducer.instituitionLoading,
    singleInstituteData: state?.InstituteReducer?.singleInstituteData,
    singleInstituteLoading: state?.InstituteReducer?.singleInstituteLoading,
    loginInfo: state.Login,
  }));

  useEffect(() => {
    let obj = {};
    obj.division = division;
    obj.district = district;
    obj.upazila = upazila;
    obj.electoralSeat = electoral;
    obj.management = management;
    obj.instituteType = instituitionList;
    obj.level = level;
    obj.studentType = student;
    obj.limit = pageRange || 10;
    obj.pageNo = currentPage;
    // console.log('obj',obj);
    dispatch(getInstitution(obj, history, authtoken));
  }, [
    division,
    district,
    upazila,
    electoral,
    management,
    instituitionList,
    level,
    student,
    pageRange,
    currentPage,
  ]);
  const handleSearch = (e) => {
    if (e.key === "Enter") {
      setSearchData(e.target.value);
      if (e.target.value) {
        dispatch(getSearchPjp(authtoken, e.target.value));
      } else {
        setSearchData("");
        // dispatch(getSuperVisor(authtoken, "SO", pageRange, currentPage));
        // dispatch(getInstitution(obj, history, authtoken));
      }
    }
  };
  console.log("instituition", instituition);
  let totalLength = instituition?.data?.totalLength;
  let totalPageNumber = Math.ceil(totalLength / pageRange);
  useEffect(() => {
    dispatch(getDivisionValue(authtoken, 0, 0));
  }, []);
  const handleDivision = (data) => {
    if (!!data) {
      setDivision(data._id);
      dispatch(getDistrictByDivision(authtoken, data._id, 0, 0));
    } else {
      setDivision("");
    }
  };
  useEffect(() => {
    if (upazilaByDist) {
      dispatch(getUpazilaValue(authtoken, 0, 0, upazilaByDist));
    } else {
      dispatch(getUpazilaValue({}));
    }
  }, [upazilaByDist]);
  useEffect(() => {
    if (parliamentaryByDist) {
      dispatch(getParliamentaryValue(authtoken, 0, 0, parliamentaryByDist));
    } else {
      dispatch(getParliamentaryValue({}));
    }
  }, [parliamentaryByDist]);
  const handleDistrict = (value) => {
    if (!!value) {
      setUpazilaByDist(value);
      setParliamentaryByDist(value);
      setDistrict(value._id);
    } else {
      setUpazilaByDist("");
      setParliamentaryByDist("");
      setDistrict("");
    }
  };
  const handleUpazila = (value) => {
    if (value) {
      setUpazila(value._id);
    } else {
      setUpazila("");
    }
  };
  const handleElectoral = (value) => {
    if (value) {
      setElectoral(value._id);
    } else {
      setElectoral("");
    }
  };
  const handleChangeManagement = (value) => {
    if (value) {
      setManagement(value.value);
    } else {
      setManagement("");
    }
  };
  const handleChangeInstituiton = (value) => {
    if (value) {
      setInstituitionList(value.value);
    } else {
      setInstituitionList("");
    }
  };
  const handleLabel = (value) => {
    if (value) {
      setLabel(value.value);
    } else {
      setLabel("");
    }
  };

  const handleStudent = (value) => {
    if (value) {
      setStudent(value.value);
    } else {
      setStudent("");
    }
  };
  const handleInstituition = (data) => {
    setOpenFirst(true);
    console.log("data", data);
    dispatch(getSingleInstitution(authtoken, data._id));
  };
  //edit
  const handleEditInstitute = (data) => {
    dispatch(storeInstituteData("singleInstitute", data));
    history.push("/edit-institute/" + data._id);
  };
  console.log("singleInstituteData", singleInstituteData);
  const handlePageRange = (e) => {
    setPageRange(e.target.value);
  };

  const handleExcelDownload = async () => {
    let obj = {};
    obj.division = division;
    obj.district = district;
    obj.upazila = upazila;
    obj.electoralSeat = electoral;
    obj.management = management;
    obj.instituteType = instituitionList;
    obj.level = level;
    obj.studentType = student;
    obj.limit = "";
    obj.pageNo = "";
    setLoading(true);
    let instituteData = [];

    await post(`/institute/institute-list`, obj, {
      headers: { Authorization: `Bearer ${authtoken}` },
    })
      .then((response) => {
        console.log("response", response);

        response?.institutes?.forEach((institute, index) => {
          let data = {};
          data.EIINNumber = institute?.eiinNumber;
          data.InstituteName = institute?.nameEnglish;
          data.Upazila = institute?.upazila?.name;
          data.District = institute?.district?.name;
          data.Division = institute?.division?.name;
          data.ContactNumber = institute?.contactNumber;

          instituteData.push(data);
        });
      })
      .catch((error) => {
        console.log("kkkk");
      });
    downloadxls(instituteData);
  };
  const downloadxls = (data) => {
    console.log(XLSX.version);
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    XLSX.writeFile(wb, "Issue.xlsx");
    setLoading(false);
  };
  return (
    <div className="user-header-top-main-area-wrap">
      <Row>
        <Col lg={3}>
          <div className="single-select-field">
            <Select
              name="division"
              options={divisionData?.divisions}
              getOptionLabel={(e) => e.name}
              getOptionValue={(e) => e.value}
              placeholder="Select Division"
              isClearable
              onChange={handleDivision}
            ></Select>
          </div>
        </Col>{" "}
        <Col lg={3}>
          <div className="single-select-field">
            <Select
              name="district"
              options={districtByDivisionData?.districts}
              getOptionLabel={(e) => e.name}
              getOptionValue={(e) => e.value}
              placeholder="Select District"
              onChange={handleDistrict}
              isClearable
            />
          </div>
        </Col>{" "}
        <Col lg={3}>
          <div className="single-select-field">
            <Select
              name="upazila"
              placeholder="Select Parlimentary Seat"
              getOptionLabel={(e) => e.name}
              getOptionValue={(e) => e.value}
              options={parliamentaryData?.electoralSeats}
              onChange={handleElectoral}
              isClearable
            />
          </div>
        </Col>{" "}
        <Col lg={3}>
          <div className="single-select-field">
            <Select
              name="upazila"
              placeholder="Select Upazila"
              getOptionLabel={(e) => e.name}
              getOptionValue={(e) => e.value}
              options={upazilaData?.upazilas}
              onChange={handleUpazila}
              isClearable
            />
          </div>
        </Col>{" "}
        <Col lg={3}>
          <div className="single-select-field">
            {/* <select
              className="form-select m-auto"
              onChange={handleChangeManagement}
            >
              <option value="">Select Management</option>
              <option value="GOVERNMENT">GOVERNMENT</option>
              <option value="NON-GOVERNMENT">NON-GOVERNMENT</option>
            </select> */}
            <Select
              placeholder="Select Management"
              getOptionLabel={(e) => e.name}
              getOptionValue={(e) => e.value}
              options={managementType}
              onChange={handleChangeManagement}
              isClearable
            />
          </div>
        </Col>{" "}
        <Col lg={3}>
          <div className="single-select-field">
            <Select
              placeholder="Select Instituition"
              getOptionLabel={(e) => e.name}
              getOptionValue={(e) => e.value}
              options={institutionList}
              onChange={handleChangeInstituiton}
              isClearable
            />
          </div>
        </Col>{" "}
        <Col lg={3}>
          <div className="single-select-field">
            <Select
              placeholder="Select Label"
              getOptionLabel={(e) => e.name}
              getOptionValue={(e) => e.value}
              options={labelList}
              onChange={handleLabel}
              isClearable
            />
          </div>
        </Col>{" "}
        <Col lg={3}>
          <div className="single-select-field">
            <Select
              placeholder="Select Student Type"
              getOptionLabel={(e) => e.name}
              getOptionValue={(e) => e.value}
              options={studentType}
              onChange={handleStudent}
              isClearable
            />
          </div>
        </Col>{" "}
      </Row>
      {/* {instituitionLoading ? (
          <div className="text-center mt-5 pt-5">
            <p>Loading</p>
          </div>
        ) : (
      <AllInstitute  instituition={instituition} />
      )} */}
      <Modal
        open={openFirst}
        onClose={() => setOpenFirst(false)}
        center
        classNames={{
          overlay: "allInstituteOverlay",
          modal: "allInstituteModal",
        }}
      >
        <div className="issue-list-title-main text-center mb-5">
          <h3> Institute Information</h3>
        </div>
        {singleInstituteLoading ? (
          <div className="text-center mt-5 pt-5">
            <p>Loading</p>
          </div>
        ) : (
          <Row>
            {/* <Col lg={6}>
            <Form.Group className="mb-3">
              <Form.Label>Issue Name</Form.Label>
              <span>:</span>
              <span> </span>
            </Form.Group>
          </Col>{" "} */}
            <Col lg={12} className="text-center">
              {singleInstituteData?.institute?.image !== "N/A" &&
              singleInstituteData?.institute?.image !== "" ? (
                <img
                  src={`${IMG_API}/${singleInstituteData?.institute?.image}`}
                  alt="image"
                  style={{ width: "100px", height: "100px" }}
                  className="mb-4"
                />
              ) : (
                <></>
              )}
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Institute Name English</Form.Label>
                <span>:</span>
                <span>{singleInstituteData?.institute?.nameEnglish}</span>
              </Form.Group>
            </Col>{" "}
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Institute Name Bangali </Form.Label>
                <span>:</span>
                <span>{singleInstituteData?.institute?.nameBangali}</span>
              </Form.Group>
            </Col>{" "}
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>EIIN Number</Form.Label>
                <span>:</span>
                <span>{singleInstituteData?.institute?.eiinNumber}</span>
              </Form.Group>
            </Col>{" "}
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Address </Form.Label>
                <span>:</span>
                <span>{singleInstituteData?.institute?.address}</span>
              </Form.Group>
            </Col>{" "}
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Division </Form.Label>
                <span>:</span>
                <span>{singleInstituteData?.institute?.division?.name}</span>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>District </Form.Label>
                <span>:</span>
                <span> {singleInstituteData?.institute?.district?.name}</span>
              </Form.Group>
            </Col>{" "}
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Upazilla </Form.Label>
                <span>:</span>
                <span> {singleInstituteData?.institute?.upazila?.name}</span>
              </Form.Group>
            </Col>{" "}
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Parliamentary Seat </Form.Label>
                <span>:</span>
                <span>
                  {" "}
                  {singleInstituteData?.institute?.electoralSeat?.name}
                </span>
              </Form.Group>
            </Col>{" "}
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Management</Form.Label>
                <span>:</span>
                <span>{singleInstituteData?.institute?.management} </span>
              </Form.Group>
            </Col>{" "}
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Location </Form.Label>
                <span>:</span>
                <span> {singleInstituteData?.institute?.location}</span>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Institute Type </Form.Label>
                <span>:</span>
                <span>{singleInstituteData?.institute?.instituteType} </span>
              </Form.Group>
            </Col>{" "}
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Level </Form.Label>
                <span>:</span>
                <span>{singleInstituteData?.institute?.level} </span>
              </Form.Group>
            </Col>{" "}
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Student Type </Form.Label>
                <span>:</span>
                <span>{singleInstituteData?.institute?.studentType} </span>
              </Form.Group>
            </Col>{" "}
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Total Student</Form.Label>
                <span>:</span>
                <span> {singleInstituteData?.institute?.totalStudent}</span>
              </Form.Group>
            </Col>{" "}
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Female Student</Form.Label>
                <span>:</span>
                <span>{singleInstituteData?.institute?.femaleStudent} </span>
              </Form.Group>
            </Col>{" "}
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Total Teacher</Form.Label>
                <span>:</span>
                <span> {singleInstituteData?.institute?.totalTeacher}</span>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Female Teacher</Form.Label>
                <span>:</span>
                <span>{singleInstituteData?.institute?.femaleTeacher} </span>
              </Form.Group>
            </Col>{" "}
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Size Of Category</Form.Label>
                <span>:</span>
                <span> {singleInstituteData?.institute?.sizeOfCategory}</span>
              </Form.Group>
            </Col>{" "}
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Head Of Institute</Form.Label>
                <span>:</span>
                <span> {singleInstituteData?.institute?.headOfInstitute}</span>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Contact Number</Form.Label>
                <span>:</span>
                <span>{singleInstituteData?.institute?.contactNumber} </span>
              </Form.Group>
            </Col>{" "}
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Support Officer </Form.Label>
                <span>:</span>
                <span>{singleInstituteData?.soUser?.name}</span>
              </Form.Group>
            </Col>{" "}
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Alternative Contact Number</Form.Label>
                <span>:</span>
                <span>
                  {" "}
                  {singleInstituteData?.institute?.alternativeContactNumber}
                </span>
              </Form.Group>
            </Col>{" "}
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Lat </Form.Label>
                <span>:</span>
                <span> {singleInstituteData?.institute?.lat}</span>
              </Form.Group>
            </Col>{" "}
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Long </Form.Label>
                <span>:</span>
                <span>{singleInstituteData?.institute?.long} </span>
              </Form.Group>
            </Col>
          </Row>
        )}
      </Modal>

      <div className="all-user-main-wra-area">
        <div className="all-user-filter-search-area mb-4 jhgdbjksldjfhvbnmcknjfb">
          <h4>Institute List</h4>
          <div className="all-user-inner-wrap">
            <div className="search-box">
              <div className="header-topbar-right-content-wrap-left-area">
                <div className="search-box-header-top-wrap">
                  <i className="fas fa-search"></i>
                  <input
                    type="text"
                    name=""
                    id=""
                    placeholder="Search..."
                    onKeyDown={(e) => handleSearch(e)}
                  />
                </div>
              </div>
            </div>
            <div className="filter-box add-division-btn">
              {loginInfo.userrole == "Demo Admin" ? (
                <button className="btn btn-secondary" disabled>
                  Download Excel
                </button>
              ) : (
                <button
                  className="btn btn-success"
                  onClick={handleExcelDownload}
                  disabled={loading}
                >
                  {loading ? "Downloading..." : "Download Excel"}
                </button>
              )}
            </div>
            <div className="filter-box">
              <Form.Select onChange={handlePageRange}>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
                <option value="500">500</option>
              </Form.Select>
            </div>
          </div>
        </div>
        <div className="user-data-main-area-wrap">
          <Table borderless>
            <thead>
              <tr>
                {" "}
                <th>Sl. No.</th>
                <th>EIIN Number</th>
                <th>Institute Name English</th>
                <th>Upazila</th>
                <th>District</th>
                <th>Division</th>
                <th>Contact Number</th>
                <th>Action</th>
              </tr>
            </thead>{" "}
            {(searchData
              ? searchPjpData?.data?.institutes || []
              : instituition?.data?.institutes || []
            ).map((data, key) => {
              {
                /* {(instituition?.data?.institutes || []).map((data, key) => { */
              }
              return (
                <>
                  <tbody>
                    <tr>
                      <td>
                        {" "}
                        {key +
                          (currentPage == 1
                            ? 0
                            : currentPage * pageRange - pageRange) +
                          1}
                      </td>
                      <td>{data?.eiinNumber}</td>
                      <td>{data?.nameEnglish}</td>
                      <td>{data?.upazila?.name}</td>
                      <td>{data?.district?.name}</td>
                      <td>{data?.division?.name}</td>
                      <td>{data?.contactNumber}</td>
                      <td>
                        <div className="d-flex ">
                          <Button
                            variant="success"
                            onClick={() => handleInstituition(data)}
                            className="user-set-routs-link-btn survay-page btn-sm"
                          >
                            Details
                          </Button>
                          {loginInfo.userrole == "Demo Admin" ? (
                            <Button
                              variant="secondary"
                              disabled
                              className="user-set-routs-link-btn survay-page btn-sm ms-2"
                            >
                              Edit
                            </Button>
                          ) : (
                            <Button
                              variant="info"
                              onClick={() => handleEditInstitute(data)}
                              className="user-set-routs-link-btn survay-page btn-sm ms-2"
                            >
                              Edit
                            </Button>
                          )}
                        </div>
                      </td>
                    </tr>{" "}
                  </tbody>
                </>
              );
            })}
          </Table>
          <Pagination
            parentClass={`paginationContainer text-right py-3`}
            reInitialize="reInitialize"
            page={totalPageNumber}
            setCurrentPage={setCurrentPage}
          />

          {/* <div className="empty-user-area">
            <p>
              Currently you have no user.{" "}
              <Link to="/create-user">create User</Link>
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default InstituteFiltering;
