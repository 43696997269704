import { toast } from "react-toastify";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  getInstituteBySOList,
  getIssueData,
  getIssueDetailsData,
  postIssuenData,
  postRemarksData,
  updateIssueData,
} from "../../helpers/api_helper.js";
import {
  addIssueFail,
  getInstituteBySOFail,
  getInstituteBySOSuccess,
  getIssue,
  getIssueDetailsFail,
  getIssueDetailsSuccess,
  getIssueFail,
  getIssueSuccess,
  postRemarksFail,
  postRemarksSuccess,
  updateIssueFail,
} from "./actions";
import {
  ADD_ISSUE,
  GET_INSTITUTE_BY_SO,
  GET_ISSUE_DETAILS,
  GET_ISSUE_LIST,
  POST_REMARKS,
  UPDATE_ISSUE,
} from "./actionTypes";
function* fetchInstituteBySO({ payload: { authtoken, id } }) {
  console.log("authtoken", authtoken);
  try {
    const response = yield call(getInstituteBySOList, authtoken, id);
    console.log("hi");
    console.log(response);
    yield put(getInstituteBySOSuccess(response));
  } catch (error) {
    if (!error.response) {
      //   history.push("/details-product")
    } else {
      let message = error.response.data.message;
      yield put(getInstituteBySOFail(message));
      // console.log("hi",message);
      toast.error(message);
    }
  }
}
function* fetchIssue({ payload: { data, history, authtoken } }) {
  console.log("data", data);
  console.log("authtoken", authtoken);
  try {
    const response = yield call(getIssueData, data, authtoken);
    console.log("hi");
    console.log(response);
    yield put(getIssueSuccess(response));
  } catch (error) {
    if (!error.response) {
      //   history.push("/details-product")
    } else {
      let message = error.response.data.message;
      yield put(getIssueFail(message));
      // console.log("hi",message);
      toast.error(message);
    }
  }
}
function* fetchIssueDetails({ payload: { authtoken, id } }) {
  try {
    const response = yield call(getIssueDetailsData, authtoken, id);
    yield put(getIssueDetailsSuccess(response));
    // console.log('line 15', response.data.users);
  } catch (error) {
    yield put(getIssueDetailsFail(error));
    console.log("hi");
  }
}
function* postRemarks({ payload: { data, history, authtoken, obj } }) {
  console.log("authtoken", authtoken);
  console.log("issue", data);
  try {
    const response = yield call(postRemarksData, data, authtoken);
    console.log("hi");
    console.log(response);
    yield put(postRemarksSuccess(response));
    toast("🦄 Remarks added successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put(getIssue(obj, history, authtoken));
  } catch (error) {
    if (!error.response) {
      toast("🦄 Issue created successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      let message = error.response.data.message;
      yield put(postRemarksFail(message));
      // console.log("hi",message);
      toast.error(message);
    }
  }
}

//ADD ISSUE
//async
const asyncPostIssueData = async (data, authtoken) => {
  try {
    const response = await postIssuenData(data, authtoken);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

function* onAddIssue({ payload: { data, history, authtoken } }) {
  try {
    const response = yield call(asyncPostIssueData, data, authtoken);
    if (response.status === "success") {
      toast("🦄 Issue created successfully !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      history.push("/issue-list");
    }
  } catch (error) {
    yield put(addIssueFail(error.message));
    toast.error(error.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
}

//update issue

function* onUpdateIssue({ payload: { id, data, token, history } }) {
  try {
    console.log("datakkk", data);
    console.log("datiiii", id);
    const response = yield call(updateIssueData, id, data, token);
    toast("🦄 Issue updated successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    if (response.status === 200) {
      history.push("/issue-list");
    }
  } catch (error) {
    console.log(error.response);
    yield put(updateIssueFail(error));
  }
}
function* IssueSaga() {
  yield takeEvery(GET_INSTITUTE_BY_SO, fetchInstituteBySO);
  yield takeEvery(GET_ISSUE_LIST, fetchIssue);
  yield takeEvery(GET_ISSUE_DETAILS, fetchIssueDetails);
  yield takeEvery(POST_REMARKS, postRemarks);
  yield takeEvery(UPDATE_ISSUE, onUpdateIssue);
  yield takeEvery(ADD_ISSUE, onAddIssue);
  // yield takeEvery(POST_REMARKS, onUpdateIssue);
}
export default IssueSaga;
