import { useEffect, useState } from "react";
import { Button, Col, Form, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-responsive-modal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Select from "react-select";
import { Row } from "reactstrap";
import {
  getDistrictByDivision,
  getDivisionValue,
  getParliamentaryValue,
  getUpazilaValue,
} from "../../store/GeoInformation/action.js";
import {
  assignInstitute,
  getInstitution,
} from "../../store/Instituition/actions";
import {
  getSearchInstitute,
  getSuperVisor,
  getUserDetails,
} from "../../store/User/actions";
import Pagination from "../Common/Pagination";

const AssignInstituteView = () => {
  // const history = useHistory();
  const [instituteList, setInstituteList] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedInstitute, setSelectedInstitute] = useState({});
  const [userId, setUserId] = useState("");
  const [openSecond, setOpenSecond] = useState(false);
  const [uodateUser, setuodateUser] = useState(false);
  const [district, setDistrict] = useState("");
  const [division, setDivision] = useState("");
  const [upazila, setUpazila] = useState("");
  const [upazilaByDist, setUpazilaByDist] = useState("");
  // const [parlimentaryByDist,setParliamentaryByDist] = useState('')
  const [parliamentaryByDist, setParliamentaryByDist] = useState("");
  const [electoral, setElectoral] = useState("");
  const [management, setManagement] = useState("");
  const [instituitionList, setInstituitionList] = useState();
  const [level, setLabel] = useState("");
  const [student, setStudent] = useState("");
  const [pageRange, setPageRange] = useState("10");
  const [currentPage, setCurrentPage] = useState(1);
  const [searchData, setSearchData] = useState("");
  const {
    authtoken,
    upazilaData,
    users,
    getUserLoading,
    superVisor,
    superVisorLoading,
    divisionData,
    districtData,
    districtByDivisionData,
    parliamentaryData,
    instituition,
    singleInstituteData,
    instituitionLoading,
    searchInstituteData,
    userDetails,
    loginInfo,
  } = useSelector((state) => ({
    authtoken: state.Login.token,
    users: state?.UserReducer?.users,
    divisionData: state.GeoInformationReducer.divisionData,
    districtData: state.GeoInformationReducer.districtData,
    parliamentaryData: state.GeoInformationReducer.parliamentaryData,
    getUserLoading: state?.UserReducer?.getUserLoading,
    superVisor: state?.UserReducer?.superVisor,
    searchInstituteData: state?.UserReducer?.searchInstituteData,
    userDetails: state?.UserReducer?.userDetails,
    superVisorLoading: state?.UserReducer?.superVisorLoading,
    districtByDivisionData:
      state?.GeoInformationReducer?.districtByDivisionData,
    upazilaData: state.GeoInformationReducer.upazilaData,
    instituition: state.InstituteReducer.instituition,
    singleInstituteData: state.InstituteReducer.singleInstituteData,
    instituitionLoading: state.InstituteReducer.instituitionLoading,
    loginInfo: state.Login,
  }));

  console.log("userDetails", userDetails);
  useEffect(() => {
    let obj = {};
    obj.division = division;
    obj.district = district;
    obj.upazila = upazila;

    // console.log('obj',obj);
    dispatch(getInstitution(obj, history, authtoken));
  }, [division, district, upazila]);

  useEffect(() => {
    dispatch(getSuperVisor(authtoken, "SO", pageRange, currentPage));
  }, [pageRange, currentPage]);
  let totalPageNumber = Math.ceil(
    superVisor?.data?.data?.totalLength / pageRange
  );
  useEffect(() => {
    dispatch(getDivisionValue(authtoken, 0, 0));
  }, []);
  const handleDivision = (data) => {
    setDivision(data._id);
    if (!!data) {
      dispatch(getDistrictByDivision(authtoken, data._id, 0, 0));
    } else {
      dispatch(getDistrictByDivision({}));
    }
  };
  useEffect(() => {
    if (upazilaByDist) {
      dispatch(getUpazilaValue(authtoken, 0, 0, upazilaByDist));
    } else {
      dispatch(getUpazilaValue(authtoken, 0, 0, upazilaByDist));
    }
  }, [upazilaByDist]);
  useEffect(() => {
    if (parliamentaryByDist) {
      dispatch(getParliamentaryValue(authtoken, 0, 0, parliamentaryByDist));
    } else {
      dispatch(getParliamentaryValue(authtoken, 0, 0, parliamentaryByDist));
    }
  }, [parliamentaryByDist]);

  useEffect(() => {
    setInstituteList(userDetails?.data?.data?.instituteList);
  }, [userDetails]);
  const handleDistrict = (value) => {
    setDistrict(value._id);
    if (!!value) {
      setUpazilaByDist(value);
      setParliamentaryByDist(value);
    } else {
      setUpazilaByDist();
      setParliamentaryByDist();
    }
  };

  const handleUpazila = (value) => {
    setUpazila(value._id);
  };
  const handleInstituition = (value) => {
    if (value) {
      setSelectedInstitute(value);
    } else {
      setSelectedInstitute({});
    }

    // dispatch(getSingleInstituteData(value));
  };
  const handleUpdateInstitute = (data) => {
    console.log("useerrr", data._id);
    setuodateUser(true);
    setUserId(data._id);
    dispatch(getUserDetails(authtoken, data._id));
  };

  // console.log('singleInstituteData',instituteList, selectedInstitute);

  const addSingleInstituition = () => {
    let findExisting = instituteList.find(
      (list) => list._id === selectedInstitute._id
    );

    if (!findExisting) {
      setInstituteList([...instituteList, selectedInstitute]);
    }

    // setSelectedInstitute([]);
  };

  const submitInstitute = (e) => {
    e.preventDefault();
    const allInstitute = instituteList.map((list) => ({ _id: list._id }));
    if (allInstitute.length) {
      var obj = {
        _id: userId,
        institute: allInstitute,
      };

      dispatch(assignInstitute(obj, history, authtoken));
      setOpenSecond(false);
      setuodateUser(false);
    }
  };
  // console.log("singleInstituteData", instituteList, selectedInstitute);
  const handlePageRange = (e) => {
    setPageRange(e.target.value);
  };
  const handleSearch = (e) => {
    if (e.key === "Enter") {
      setSearchData(e.target.value);
      if (e.target.value) {
        dispatch(getSearchInstitute(authtoken, e.target.value));
      } else {
        setSearchData("");
        dispatch(getSuperVisor(authtoken, "SO", pageRange, currentPage));
      }
    }
  };

  return (
    <div>
      <Modal
        open={openSecond}
        onClose={() => setOpenSecond(false)}
        center
        classNames={{
          overlay: "add-divisionOverlay",
          modal: "add-division-Modal profile-view-bndfv",
        }}
      >
        <div className="add-division-popup-form">
          <div className="add-division-title-top">
            <div className="text-center mb-4">
              <h3>Assign Institute</h3>
            </div>
            <Form>
              <Row>
                <Col lg={3}>
                  {" "}
                  <Form.Group className="mb-3">
                    {/* <Form.Label>Select Division</Form.Label>  */}
                    <Select
                      name="division"
                      options={divisionData?.divisions}
                      getOptionLabel={(e) => e.name}
                      getOptionValue={(e) => e.value}
                      placeholder="Select Division"
                      isClearable
                      onChange={handleDivision}
                    ></Select>
                  </Form.Group>{" "}
                </Col>{" "}
                <Col lg={3}>
                  {" "}
                  <Form.Group className="mb-3">
                    {/* <Form.Label>Select District</Form.Label>  */}
                    <Select
                      name="district"
                      options={districtByDivisionData?.districts}
                      getOptionLabel={(e) => e.name}
                      getOptionValue={(e) => e.value}
                      placeholder="Select District"
                      onChange={handleDistrict}
                      isClearable
                    />
                  </Form.Group>{" "}
                </Col>
                <Col lg={3}>
                  {" "}
                  <Form.Group className="mb-3">
                    {/* <Form.Label>Select Upazila</Form.Label>  */}
                    <Select
                      name="upazila"
                      placeholder="Select Upazila"
                      getOptionLabel={(e) => e.name}
                      getOptionValue={(e) => e.value}
                      options={upazilaData?.upazilas}
                      onChange={handleUpazila}
                      isClearable
                    />
                  </Form.Group>{" "}
                </Col>{" "}
                <Col lg={6}>
                  <Form.Group className="mb-3">
                    {/* <Form.Label> Institute Name</Form.Label> */}

                    <Select
                      name="Institute"
                      placeholder="Select Institute"
                      getOptionLabel={(e) => e.nameEnglish}
                      getOptionValue={(e) => e.value}
                      options={instituition?.data?.institutes}
                      onChange={handleInstituition}
                      isClearable
                    />
                  </Form.Group>
                </Col>{" "}
                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Button variant="success" onClick={addSingleInstituition}>
                      Add
                    </Button>
                  </Form.Group>
                </Col>{" "}
              </Row>
              <hr />
              <Table borderless>
                <thead>
                  <tr>
                    <th>SL.</th>
                    <th>Institute Name</th>
                    <th>Head Name</th>
                    <th>Email</th>
                    <th>Phone Number </th>
                    <th>Address</th>
                    <th>Action</th>
                  </tr>
                </thead>

                {(instituteList || []).map((data, key) => {
                  return (
                    <>
                      <tbody>
                        <tr>
                          <td>{key + 1}</td>
                          <td>{data?.nameEnglish}</td>
                          <td>{data?.headOfInstitute}</td>
                          <td>{data?.email}</td>
                          <td>{data?.contactNumber}</td>
                          <td>{data?.address}</td>
                          <td>
                            <button
                              type="button"
                              className="btn btn-outline-danger btn-sm "
                              onClick={() =>
                                setInstituteList(
                                  instituteList.filter(
                                    (ins) => ins._id !== data._id
                                  )
                                )
                              }
                            >
                              <i class="fa-solid fa-xmark"></i>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </>
                  );
                })}
              </Table>
              <Button
                className="btn btn-info me-2 text-white"
                onClick={(e) => submitInstitute(e)}
              >
                Assign Institute
              </Button>
            </Form>
          </div>
        </div>
      </Modal>
      <Modal
        open={uodateUser}
        onClose={() => setuodateUser(false)}
        center
        classNames={{
          overlay: "add-divisionOverlay",
          modal: "add-division-Modal profile-view-bndfv",
        }}
      >
        <div className="add-division-popup-form">
          <div className="add-division-title-top">
            <div className="text-center mb-4">
              <h3>Update Institute</h3>
            </div>
            <Form>
              <Row>
                <Col lg={3}>
                  {" "}
                  <Form.Group className="mb-3">
                    {/* <Form.Label>Select Division</Form.Label>  */}
                    <Select
                      name="division"
                      options={divisionData?.divisions}
                      getOptionLabel={(e) => e.name}
                      getOptionValue={(e) => e.value}
                      placeholder="Select Division"
                      isClearable
                      onChange={handleDivision}
                    ></Select>
                  </Form.Group>{" "}
                </Col>{" "}
                <Col lg={3}>
                  {" "}
                  <Form.Group className="mb-3">
                    {/* <Form.Label>Select District</Form.Label>  */}
                    <Select
                      name="district"
                      options={districtByDivisionData?.districts}
                      getOptionLabel={(e) => e.name}
                      getOptionValue={(e) => e.value}
                      placeholder="Select District"
                      onChange={handleDistrict}
                      isClearable
                    />
                  </Form.Group>{" "}
                </Col>
                <Col lg={3}>
                  {" "}
                  <Form.Group className="mb-3">
                    {/* <Form.Label>Select Upazila</Form.Label>  */}
                    <Select
                      name="upazila"
                      placeholder="Select Upazila"
                      getOptionLabel={(e) => e.name}
                      getOptionValue={(e) => e.value}
                      options={upazilaData?.upazilas}
                      onChange={handleUpazila}
                      isClearable
                    />
                  </Form.Group>{" "}
                </Col>{" "}
                <Col lg={6}>
                  <Form.Group className="mb-3">
                    {/* <Form.Label> Institute Name</Form.Label> */}

                    <Select
                      name="Institute"
                      placeholder="Select Institute"
                      getOptionLabel={(e) => e.nameEnglish}
                      getOptionValue={(e) => e.value}
                      options={instituition?.data?.institutes}
                      onChange={handleInstituition}
                      isClearable
                    />
                  </Form.Group>
                </Col>{" "}
                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Button variant="success" onClick={addSingleInstituition}>
                      Add
                    </Button>
                  </Form.Group>
                </Col>{" "}
              </Row>
              <hr />
              <Table borderless>
                <thead>
                  <tr>
                    <th>SL.</th>
                    <th>Institute Name</th>
                    <th>Head Name</th>

                    <th>Phone Number </th>
                    <th>Address</th>
                    <th>Action</th>
                  </tr>
                </thead>
                {(instituteList || []).map((data, key) => {
                  return (
                    <>
                      <tbody>
                        <tr>
                          <td>{key + 1}</td>
                          <td>{data?.nameEnglish}</td>
                          <td>{data?.headOfInstitute}</td>
                          <td>{data?.contactNumber}</td>
                          <td>{data?.address}</td>
                          <td>
                            <button
                              type="button"
                              className="btn btn-outline-danger btn-sm "
                              onClick={() =>
                                setInstituteList(
                                  instituteList.filter(
                                    (singleIns) => singleIns._id !== data._id
                                  )
                                )
                              }
                            >
                              <i class="fa-solid fa-xmark"></i>
                            </button>
                          </td>
                        </tr>{" "}
                      </tbody>
                    </>
                  );
                })}
              </Table>
              <Button
                className="btn btn-success"
                variant=" outline"
                onClick={(e) => submitInstitute(e)}
              >
                {" "}
                Update Institute
              </Button>{" "}
            </Form>
          </div>
        </div>
      </Modal>
      <div className="all-user-main-wra-area">
        <div className="all-user-filter-search-area mb-4 jhgdbjksldjfhvbnmcknjfb">
          <h4>Assign Institute</h4>
          <div className="all-user-inner-wrap">
            <div className="search-box">
              <div className="header-topbar-right-content-wrap-left-area">
                <div className="search-box-header-top-wrap">
                  <i className="fas fa-search"></i>
                  <input
                    type="text"
                    name=""
                    id=""
                    placeholder="Search..."
                    onKeyDown={(e) => handleSearch(e)}
                  />
                </div>
              </div>
            </div>
            <div className="filter-box">
              <select className="form-select m-auto" onChange={handlePageRange}>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>{" "}
            {/* <div className="filter-box add-division-btn">
              <button
                className="btn btn-outline-secondary"
                onClick={onOpenModal}
              >
                Add Institute
              </button>
            </div> */}
          </div>
        </div>
        <div className="user-data-main-area-wrap">
          <Table borderless>
            <thead>
              <tr>
                <th>No.</th>
                <th>Full Name </th>
                <th>User ID</th>
                <th>Email</th>
                <th>Phone Number </th>
                <th>Role</th>
                <th>Regional Supervisor</th>
                <th>Action</th>
              </tr>
            </thead>

            {(searchData
              ? searchInstituteData?.data?.data?.users || []
              : superVisor?.data?.data?.users || []
            ).map((data, key) => {
              return (
                <>
                  <tbody>
                    <tr>
                      <td>
                        {" "}
                        {key +
                          (currentPage == 1
                            ? 0
                            : currentPage * pageRange - pageRange) +
                          1}
                      </td>
                      <td>{data?.name}</td>
                      <td>{data?.employeeId}</td>
                      <td>{data?.email}</td>
                      <td>{data?.phoneNumber}</td>
                      <td>{data?.role}</td>
                      <td>{data?.linemanager?.name}</td>
                      {data?.assignSchool.length ? (
                        <td>
                          {loginInfo.userrole == "Demo Admin" ? (
                            <Button className="btn btn-secondary" disabled>
                              Update Institute
                            </Button>
                          ) : (
                            <Button
                              className="btn btn-success"
                              variant=" outline"
                              onClick={() => handleUpdateInstitute(data)}
                            >
                              Update Institute
                            </Button>
                          )}
                        </td>
                      ) : (
                        <td>
                          <Button
                            className="btn btn-info me-2 text-white"
                            // onClick={() => {
                            //   setOpenSecond(true);
                            //   setUserId(data._id);
                            // }}
                            onClick={() => handleUpdateInstitute(data)}
                          >
                            Assign Institute
                          </Button>
                        </td>
                      )}
                    </tr>{" "}
                  </tbody>
                </>
              );
            })}
          </Table>
          <Pagination
            parentClass={`paginationContainer text-right py-3`}
            reInitialize="reInitialize"
            page={totalPageNumber}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    </div>
  );
};

export default AssignInstituteView;
