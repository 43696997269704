import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import "react-pro-sidebar/dist/css/styles.css";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import icon7 from "./icon/building-4.svg";
import pjp from "./icon/cube.svg";
// import icon8 from "./icon/cd.svg";
import icon1 from "./icon/dashboard.svg";
import icon5 from "./icon/location.svg";
import icon2 from "./icon/profile-circle.svg";
import issue from "./icon/reconcil.svg";
import icon10 from "./icon/tick-square.svg";
const NavbarMainLink = () => {
  const [dropClose, setDropClose] = useState(false);
  const { authtoken, users, loginInfo } = useSelector((state) => ({
    authtoken: state.Login.token,
    loginInfo: state.Login,
    users: state?.UserReducer?.users,
  }));

  console.log("loginInfooo", loginInfo);
  return (
    <div className="navbar-item-main-wrap-nav-items">
      <h6 className="ms-3">Menu</h6>

      <ul>
        <li onClick={() => setDropClose(!dropClose)}>
          <NavLink
            exact
            activeClassName="nav-link-selected"
            to="/admindashboard"
          >
            <img src={icon1} alt="" />
            <span>Dashboard</span>
          </NavLink>
        </li>{" "}
        <li className="nav-link-dropdown-main">
          <a>
            <img src={icon2} alt="" />
            <Dropdown autoClose={false}>
              <Dropdown.Toggle variant="success" id="dropdown-autoclose-false">
                <span>Users</span>
                <b>
                  <i class="fa-solid fa-chevron-down"></i>
                </b>
              </Dropdown.Toggle>

              <Dropdown.Menu
                show={
                  window.location.pathname === "/user-list" ||
                  window.location.pathname === "/assign-institute"
                    ? true
                    : false
                }
              >
                <div className="">
                  <NavLink
                    exact
                    activeClassName="nav-link-selected"
                    to="/user-list"
                  >
                    User List
                  </NavLink>
                  <NavLink
                    activeClassName="nav-link-selected"
                    to="/assign-institute"
                  >
                    Assign Institute
                  </NavLink>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </a>
        </li>{" "}
        <li className="nav-link-dropdown-main ">
          <a>
            <img src={icon7} alt="" />
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <span>Institute</span>
                <b>
                  <i class="fa-solid fa-chevron-down"></i>
                </b>
              </Dropdown.Toggle>

              <Dropdown.Menu
                show={
                  window.location.pathname === "/create-institute" ||
                  window.location.pathname === "/institute-list"
                    ? true
                    : false
                }
              >
                <div className="">
                  {loginInfo.userrole == "Demo Admin" ? (
                    <NavLink
                      to="#"
                      style={{
                        pointerEvents: "none",
                        color: "gray",
                      }}
                    >
                      Create Institute
                    </NavLink>
                  ) : (
                    <NavLink
                      exact
                      activeClassName="nav-link-selected"
                      to="/create-institute"
                    >
                      Create Institute
                    </NavLink>
                  )}

                  <NavLink
                    activeClassName="nav-link-selected"
                    to="/institute-list"
                  >
                    Institute list
                  </NavLink>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </a>
        </li>{" "}
        <li className="nav-link-dropdown-main ">
          <a>
            <img src={issue} alt="" />
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <span>Issue</span>
                <b>
                  <i class="fa-solid fa-chevron-down"></i>
                </b>
              </Dropdown.Toggle>

              <Dropdown.Menu
                show={
                  window.location.pathname === "/add-issue" ||
                  window.location.pathname === "/issue-list"
                    ? true
                    : false
                }
              >
                <div className="">
                  <NavLink
                    exact
                    activeClassName="nav-link-selected"
                    to="/add-issue"
                  >
                    Create Issue
                  </NavLink>

                  <NavLink activeClassName="nav-link-selected" to="/issue-list">
                    Issue list
                  </NavLink>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </a>
        </li>{" "}
        <li className="nav-link-dropdown-main jnmf custom_height  ">
          <a>
            <img src={icon5} alt="" />
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <span>GEO Info</span>
                <b>
                  <i class="fa-solid fa-chevron-down"></i>
                </b>
              </Dropdown.Toggle>

              <Dropdown.Menu
                show={
                  window.location.pathname === "/division" ||
                  window.location.pathname === "/district" ||
                  window.location.pathname === "/upazila" ||
                  window.location.pathname === "/parliamentary-seat"
                    ? true
                    : false
                }
              >
                <div className="">
                  <NavLink activeClassName="nav-link-selected" to="/division">
                    Division
                  </NavLink>
                  <NavLink
                    exact
                    activeClassName="nav-link-selected"
                    to="/district"
                  >
                    District
                  </NavLink>
                  <NavLink
                    exact
                    activeClassName="nav-link-selected"
                    to="/upazila"
                  >
                    Upazila
                  </NavLink>
                  <NavLink
                    exact
                    activeClassName="nav-link-selected"
                    to="/parliamentary-seat"
                  >
                    Parliamentary Seat
                  </NavLink>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </a>
        </li>{" "}
        <li>
          <NavLink exact activeClassName="nav-link-selected" to="/pjp">
            <img src={pjp} alt="" />
            <span>PJP</span>
          </NavLink>
        </li>
        <li className="nav-link-dropdown-main ">
          <a>
            <img src={icon10} alt="" />
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <span>Attendence</span>
                <b>
                  <i class="fa-solid fa-chevron-down"></i>
                </b>
              </Dropdown.Toggle>

              <Dropdown.Menu
                show={
                  window.location.pathname === "/attendance" ||
                  window.location.pathname === "/issue-list"
                    ? true
                    : false
                }
              >
                <div className="">
                  {loginInfo.userrole == "Demo Admin" ? (
                    <NavLink
                      to="#"
                      style={{ pointerEvents: "none", color: "gray" }}
                    >
                      Daily Attendance Report
                    </NavLink>
                  ) : (
                    <NavLink
                      exact
                      activeClassName="nav-link-selected"
                      to="/attendance"
                    >
                      Daily Attendance Report
                    </NavLink>
                  )}
                  {loginInfo.userrole == "Demo Admin" ? (
                    <NavLink
                      to="#"
                      style={{ pointerEvents: "none", color: "gray" }}
                    >
                      Attendance Summary
                    </NavLink>
                  ) : (
                    <NavLink
                      activeClassName="nav-link-selected"
                      to="/attendance-summary"
                    >
                      Attendance Summary
                    </NavLink>
                  )}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </a>
        </li>{" "}
      </ul>
    </div>
  );
};

export default NavbarMainLink;
