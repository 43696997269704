import { useEffect, useState } from "react";
import { Button, Form, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-responsive-modal";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import {
  addNewDistrict,
  getDistrictValue,
  getDivisionValue,
  storeDistrictData,
  updateDistrict,
} from "../../store/actions";
import Pagination from "../Common/Pagination";

const DistrictView = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [createDivistion, setcreateDivistion] = useState(false);
  const [editDivistion, setEditDivistion] = useState(false);
  const [district, setDistrict] = useState("");
  const [division, setDivision] = useState("");
  const [distByDiv, setDistByDiv] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageRange, setPageRange] = useState(10);
  const [key, setKey] = useState("");
  const [objData, setObjData] = useState({});
  const {
    divisionData,
    loading,
    authtoken,
    editDistrictInfo,
    districtData,
    loginInfo,
  } = useSelector((state) => ({
    authtoken: state.Login.token,
    loading: state.GeoInformationReducer.isLoading,
    divisionData: state.GeoInformationReducer.divisionData,
    districtData: state.GeoInformationReducer.districtData,
    editDistrictInfo: state.GeoInformationReducer.editDistrictInfo,
    loginInfo: state.Login,
  }));

  //division list
  useEffect(() => {
    dispatch(getDivisionValue(authtoken, 0, 0));
  }, []);

  //district list
  useEffect(() => {
    if (distByDiv) {
      dispatch(getDistrictValue(authtoken, currentPage, pageRange, distByDiv));
    } else {
      dispatch(getDistrictValue(authtoken, currentPage, pageRange, distByDiv));
    }
  }, [currentPage, pageRange, distByDiv]);

  let totalPageNumber = Math.ceil(districtData?.totalLength / pageRange);
  //district list

  //add district
  const onSubmit = (e) => {
    e.preventDefault();
    let obj = {};
    obj.name = district;
    obj.division = division;
    dispatch(addNewDistrict(obj, history, authtoken));
    setcreateDivistion(false);
  };
  //add division

  //edit division
  const onEditSubmit = (e, data) => {
    e.preventDefault();
    let obj = {};
    obj.name = objData?.[key]?.name ? objData?.[key]?.name : data?.name;
    obj.division = objData?.[key]?.division
      ? objData?.[key]?.division
      : data?.division._id;
    // obj.name = district ? district : data.name;
    // obj.division = division ? division : data.division._id;
    dispatch(updateDistrict(data._id, obj, authtoken, history));
    setEditDivistion(false);
  };
  // console("objDataaa", objData);

  const handleEditDistrict = (data, id) => {
    setKey(id);
    dispatch(storeDistrictData("singleDistrict", data, id));
  };

  const handleMainFilter = (value) => {
    if (!!value) {
      setDistByDiv(value);
    } else {
      setDistByDiv();
    }
  };

  return (
    <div>
      <Modal
        open={createDivistion}
        onClose={() => setcreateDivistion(false)}
        center
        classNames={{
          overlay: "add-divisionOverlay",
          modal: "add-division-Modal",
        }}
      >
        <div className="add-division-popup-form">
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Select Division</Form.Label>
              <Select
                options={divisionData?.divisions}
                isClearable
                getOptionLabel={(e) => e.name}
                getOptionValue={(e) => e.value}
                onChange={(e) => setDivision(e._id)}
              />
            </Form.Group>{" "}
            <Form.Group className="mb-3">
              <Form.Label>Type District</Form.Label>
              <Form.Control
                type="text"
                placeholder="Type division"
                onChange={(e) => setDistrict(e.target.value)}
              />
            </Form.Group>
            <Button
              variant="success"
              type="submit"
              onClick={(e) => onSubmit(e)}
            >
              Add District
            </Button>
          </Form>
        </div>
      </Modal>
      <Modal
        open={editDivistion}
        onClose={() => setEditDivistion(false)}
        center
        classNames={{
          overlay: "add-divisionOverlay",
          modal: "add-division-Modal",
        }}
      >
        <div className="add-division-popup-form">
          <Form className="add-division-popup-select-data">
            <Form.Group className="mb-3">
              <Form.Label>Select Division</Form.Label>
              <Select
                options={divisionData?.divisions}
                isClearable
                placeholder="Division"
                getOptionLabel={(e) => e.name}
                getOptionValue={(e) => e.value}
                // onChange={(e) => setDivision(e._id)}
                onChange={(e) => {
                  setDivision(e._id);
                  setObjData({
                    ...objData,
                    [key]: {
                      ...objData[key],
                      division: e._id,
                    },
                  });
                }}
                defaultValue={divisionData?.divisions?.filter(
                  (dta) =>
                    dta._id === editDistrictInfo?.[key]?.data?.division?._id
                )}
              />
            </Form.Group>{" "}
            <Form.Group className="mb-3">
              <Form.Label>Type District</Form.Label>
              <Form.Control
                type="text"
                placeholder="Type district"
                onChange={(e) => {
                  setDistrict(e.target.value);
                  setObjData({
                    ...objData,
                    [key]: {
                      ...objData[key],
                      name: e.target.value,
                    },
                  });
                }}
                defaultValue={editDistrictInfo?.[key]?.data?.name}
              />
            </Form.Group>
            <Button
              variant="success"
              type="submit"
              onClick={(e) => onEditSubmit(e, editDistrictInfo?.[key].data)}
            >
              Update District
            </Button>
          </Form>
        </div>
      </Modal>
      <div className="all-user-main-wra-area">
        <div className="all-user-filter-search-area mb-4 jhgdbjksldjfhvbnmcknjfb">
          <h4>District List</h4>
          <div className="all-user-inner-wrap">
            <div className="search-box">
              <div className="header-topbar-right-content-wrap-left-area">
                <div className="search-box-header-top-wrap">
                  <i className="fas fa-search"></i>
                  <input type="search" name="" id="" placeholder="Search..." />
                </div>
              </div>
            </div>
            <div className="filter-box ghigbrn">
              <Select
                name="subFilter"
                classNamePrefix="select2-selection"
                options={divisionData?.divisions}
                getOptionLabel={(e) => e.name}
                getOptionValue={(e) => e.value}
                onChange={handleMainFilter}
                placeholder="Select Division"
                isClearable
              />
            </div>{" "}
            <div className="filter-box">
              <Form.Select
                aria-label="10"
                onChange={(e) => {
                  setPageRange(e.target.value);
                }}
                // value={pageRange}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </Form.Select>
            </div>
            <div className="filter-box add-division-btn">
              {loginInfo.userrole == "Demo Admin" ? (
                <button className="btn btn-secondary" disabled>
                  Create District
                </button>
              ) : (
                <button
                  className="btn btn-success"
                  onClick={() => setcreateDivistion(true)}
                >
                  Create District
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="user-data-main-area-wrap">
          <Table borderless>
            <thead>
              <tr>
                <th>No.</th>
                <th>Division </th>
                <th>District </th>

                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {districtData?.districts?.length > 0 ? (
                districtData?.districts.map((data, idx) => (
                  <tr key={idx}>
                    <td>
                      {" "}
                      {idx +
                        (currentPage == 1
                          ? 0
                          : currentPage * pageRange - pageRange) +
                        1}
                    </td>
                    <td>{data.division.name}</td>

                    <td>{data.name}</td>
                    <td>
                    {loginInfo.userrole == "Demo Admin" ? (
                        <span className="d-inline-block">
                          <Button
                            variant="secondary"
                            className="btn btn-sm text-white"
                            disabled
                          >
                            <i class="fa-solid fa-eye-dropper"></i>
                          </Button>
                        </span>
                      ) : (
                        <OverlayTrigger
                        overlay={<Tooltip id="tooltip-disabled">Edit</Tooltip>}
                      >
                        <span className="d-inline-block">
                          <Button
                            className="btn btn-info me-2 btn-sm text-white"
                            onClick={() => {
                              setEditDivistion(true);
                              handleEditDistrict(data, idx);
                            }}
                          >
                            <i class="fa-solid fa-eye-dropper"></i>
                          </Button>
                        </span>
                      </OverlayTrigger>
                      )}
                   
                    </td>
                  </tr>
                ))
              ) : (
                <></>
              )}
            </tbody>
          </Table>
          <Pagination
            parentClass={`paginationContainer text-right py-3`}
            reInitialize="reInitialize"
            page={totalPageNumber}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    </div>
  );
};

export default DistrictView;
